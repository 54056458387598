import AnswerListModel from "lf/slides/AnswerList/AnswerListModel";
import ClozeModel from "lf/slides/Cloze/ClozeModel";
import DragDropModel from "lf/slides/DragDrop/DragDropModel";
import FreetextModel from "lf/slides/FreeText/FreetextModel";
import HotspotModel from "lf/slides/HotSpot/HotspotModel";
import DropDownModel from "lf/slides/DropDown/DropDownModel";

// SingleChoice & MultipleChoice
AnswerListModel.prototype[ 'reset' ] = function( this:AnswerListModel ) {
    for( const answer of this.answers ) {
        answer.selected = false;
    }
    this.answered = false;
}

// Cloze & DropDown:
ClozeModel.prototype[ 'reset' ] = function( this:ClozeModel ) {
    this.userInputs = {};
    this.answered = false;
}

// DragDrop:
DragDropModel.prototype[ 'reset' ] = function( this:DragDropModel ) {
    for( const drag of this.drags ) {
        drag.dropped = 0;
    }
    this.answered = false;
}

// Freetext:
FreetextModel.prototype[ 'reset' ] = function( this:FreetextModel ) {
    this.value = '';
    this.answered = false;
}

// HotSpot:
HotspotModel.prototype[ 'reset' ] = function( this:HotspotModel ) {
    this.set( 'userPoints', [] );
    this.answered = false;
}
