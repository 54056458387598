

import Template from 'ln/template/TemplateManager';
Template.add( { lf:{
  "base-slide": "<h2 class=\"text-style -title\">[[ title ]]</h2><p class=\"text-style -text\">[[ text ]]</p>",
  "cloze-slide": "<h4 class=\"text-style -h4 margin-box -bottom-small\">[[ title ]]</h4>[% if (opt.description) { %]<p class=\"text-style -question\">[[= opt.description ]]</p>[% } %]<p js=\"text\" class=\"cloze-container text-style -p\">[[ parsedText ]]</p>",
  "cloze-gap": "<input class=\"feedback-box text-style -p\" type=\"text\" name=\"[[ label ]]\" value=\"[[ value ]]\">",
  "answerlist-slide": "<h4 class=\"title text-style -h4 margin-box -bottom-small\">[[ title ]]</h4>[% if (opt.text) { %]<p class=\"text-style -question\">[[= opt.text ]]</p>[% } %]<div class=\"margin-box -bottom-medium\"><div js=\"answer-container\" class=\"grid-layout -gutter\"></div></div>",
  "singlechoice-answer": "<div class=\"radio answer-view item -w1 -small-1-2 \"><label><input type=\"radio\"><p class=\" text\">[[ text ]]</p></label></div>",
  "multiplechoice-answer": "<div class=\"chechbox answer-view item -w1 -small-1-2 \"><label><input type=\"checkbox\"><p class=\"text\">[[ text ]]</p></label></div>",
  "reveal-slide": "<h4 class=\"text-style -h4 margin-box -bottom-small\">[[ title ]]</h4><p class=\"text-style -question\">[[ text ]]</p><div class=\"grid-layout -gutter\"><div js=\"buttons\" class=\"item -w1 -medium-1-2\"></div><div js=\"target\" class=\"item -w1 -medium-1-2\"></div></div>",
  "reveal-button": "<button class=\"button-style -main -hover margin-box -bottom-small\">[[ label ]]</button>",
  "reveal-content": "<h3 class=\"subtitle\">[[ label ]]</h3><p class=\"text-style -question\">[[ text ]]</p>",
  "freetext-slide": "<h4 class=\"title text-style -h4 margin-box -bottom-small\">[[ title ]]</h4><p class=\"text text-style -question\">[[ text ]]</p><textarea js=\"text\"></textarea>",
  "hotspot-slide": "<h4 class=\"text-style -h4 margin-box -bottom-small\">[[ title ]]</h4><p class=\"text-style -question\">[[ text ]]</p><div class=\"flex-layout -center\"><div js=\"points\" class=\"abs-layout -stretch\"><img class=\"image-responsive -full\" src=\"[[ this.imagePath(image, 'large', 'png') ]]\"></div></div>",
  "hotspot-point": "<div class=\"hotspot-point item shadow-box feedback-box\" style=\"top: [[ top * 100 ]]%; left: [[ left * 100 ]]%;\"></div>",
  "hotspot-hotspot": "<div class=\"hotspot-rect item\" style=\"top: [[ top * 100 ]]%; left: [[ left * 100 ]]%; width:[[ width * 100 ]]%; height: [[ height * 100 ]]%\"></div>",
  "dragdrop-slide": "<h4 class=\"title text-style -h4 margin-box -bottom-small\">[[ title ]]</h4><div class=\"drag-drop margin-box -bottom-medium\"><div class=\"grid-layout\"><div js=\"drag-container\" class=\"drag-container item -w1\"></div><div js=\"drop-container\" class=\"drop-container item -w1 grid-layout\"></div></div></div>",
  "dragdrop-dropitem": "<div class=\"drop-node item -w1 -small-1-3 padding-box -right-medium\"><div class=\"text text-style -h5\">[[ opt.text ]]</div><div js=\"target\" class=\"drop-target\"></div></div>",
  "dragdrop-dragitem": "<div js=\"drag-node\" class=\"drag-node item\">[% if( opt.image ) { %]<img class=\"image\" src=\"[[ this.imagePath(image, 'medium', image.ext) ]]\">[% } %]<p class=\"text text-style -p-small\">[[ opt.text ]]</p></div>",
  "dragdrop-cloze-slide": "<div class=\"title\">[[ title ]] </div><div js=\"text\" class=\"flex-layout -items-center margin-box -v\">[[= text ]]</div><div class js=\"drop-container\"></div><div class=\"placeholder-box -large margin-box -v\" js=\"drag-container\"></div>",
  "dragdrop-drop-cloze-item": "<div class=\"margin-box -h-small\" js=\"drop-node\"><div js=\"target\" class=\"target placeholder-box border-box -all\" data-index=\"[[target]]\"></div> </div>",
  "dragdrop-drag-cloze-item": "<div js=\"drag-node\" class=\"drag-node feedback-box shadow-box\"><div class=\"padding-box -all-small\"><p>[[ opt.text ]]</p></div></div>",
  "dragdrop-sentence-slide": "<h4 class=\"text-style -h4 margin-box -bottom-small\">[[ title ]]</h4><p class=\"text-style -question\">[[ text ]]</p><div js=\"drop-container\" class=\"margin-box -v\"></div><div js=\"drag-container\" class=\"placeholder-box margin-box -v\"></div>",
  "dragdrop-drag-sentence-item": "[[= this.import('lf.dragdrop-dragitem', opt) ]]",
  "dragdrop-drop-sentence-item": "<div class=\"drop-node\"><div js=\"target\" class=\"target placeholder-box\"></div></div>",
  "dropdown-slide": "<h4 class=\"title text-style -h4 margin-box -bottom-small\">[[ title ]]</h4><p js=\"text\" class=\"margin-box -bottom-medium text-style -p\">[[ parsedText ]]</p>",
  "dropdown-select": "<select class=\"dropdown [[ label ]] text-style -p\" name=\"[[ label ]]\"><option>--</option></select>",
  "dropdown-option": "<option class=\"text-style -p\" value=\"[[ text ]]\">[[ text ]]</option>",
  "tooltip-slide": "<div class=\"tooltip-slide\"><h4 class=\"text-style -h4 margin-box -bottom-small\">[[ title ]]</h4><p class=\"text-style -question\">[[= text ]]</p> <div class=\"flex-layout -center\"><div js=\"tooltips\" class=\"tooltips abs-layout -stretch\"><img class=\"image-responsive -full\" src=\"[[ this.imagePath(image, 'large', 'png') ]]\"><div js=\"areas\" class=\"item -stretch abs-layout\"></div></div></div></div>",
  "tooltip-area": "<div class=\"tooltip-area item\" style=\"top: [[ top * 100 ]]%; left: [[ left * 100 ]]%; width:[[ width * 100 ]]%; height: [[ height * 100 ]]%\"></div>",
  "tooltip-tooltip": "<div js=\"tooltip-container\" class=\"tooltip-container fixed-layout color-box -white shadow-box padding-box -all\">[[= text ]]</div>",
  "matrixchoice-slide": "<h4 class=\"text-style -h4 margin-box -bottom-small\">[[ title ]]</h4><p class=\"text-style -question\">[[ text ]]</p><table class=\"matrix-block\"><thead class=\"matrix-header\"><tr><th></th>[% opt.labels.forEach( function( label ) { %]<th>[[ label.text ]]</th>[% }); %]</tr></thead><tbody class=\"question-container\">[% opt.questions.forEach( function( question, questionIndex ) { %]<tr class=\"input\"> <td>[[ question.text ]]</td> [% question.answers.forEach( function( answer, index ) { %]<td><input type=\"radio\" class=\"matrix-input\" data-question=\"[[ questionIndex ]]\" data-index=\"[[ answer.index ]]\" ></td>[% }); %]</tr>[% }); %]</tbody></table>",
  "matrixbinarychoice-slide": "<h4 class=\"text-style -h4 margin-box -bottom-small\">[[ title ]]</h4><p class=\"text-style -question\">[[ text ]]</p><table class=\"matrix-block\"><thead class=\"matrix-header\"><tr><th></th><th>Richtig</th><th>Falsch</th></tr></thead><tbody class=\"question-container\">[% opt.questions.forEach( function( question, questionIndex ) { %]<tr class=\"input\"> <td>[[ question.text ]]</td> [% question.answers.forEach( function( answer, index ) { %]<td><input type=\"radio\" class=\"matrix-input\" data-question=\"[[ questionIndex ]]\" data-index=\"[[ answer.index ]]\"></td>[% }); %]</tr>[% }); %]</tbody></table>"
} } );

