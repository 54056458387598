import View from 'ln/view/View';

import LernFragen from 'lf/LernFragen';
import SlideModel from 'lf/slides/Slide/SlideModel';

import type ContainerModel from '../container/ContainerModel';


class ExamQuiz extends View {

    public model: SlideModel;
    public lernfragen: LernFragen;

	constructor( model: SlideModel ) {
		super();
        this.defaultTemplate = 'lm.quiz-element';
        this.model = model;
    }
    
    protected  init() {
        
        this.lernfragen = new LernFragen().defaultSetup();
        //models are mapped by lernfragenbuch

        var slide = this.model;

        this.lernfragen.render( this.node.js('quiz') ) as LernFragen;
        
        this.lernfragen.renderer.userAnswered.add( this.setAnswered.bind(this) );
        this.lernfragen.renderer.userInput.add( this.setAnswered.bind(this) );

         this.lernfragen.renderer.show( slide );
    }

    
    //set the question as answered
    protected setAnswered( item ) {
        item.answered = true;
        const parent = <ContainerModel> <unknown> this.model.parent;
        parent.change.dispatch();
    }
    
    protected renderData() {
        return this.model;
	}
}

export default ExamQuiz;

