import Container from '../container/Container';
import CompositeArticleModel from './CompositeArticleModel';
import ElementModelRendererIoC from '../element/ElementModelRendererIoC';


class CompositeArticle extends Container {

    readonly model: CompositeArticleModel;

    constructor(model: CompositeArticleModel, elementsIoC: ElementModelRendererIoC) {
        super(model, 'lm.compositearticle-element', elementsIoC);
    }

    protected get elementContainerNode() {
        return this.node.js('elements');
    }
}


export default CompositeArticle;
