import type { CompletionStatus, SuccessStatus } from '../lernfragen/ScormApi';
import ContainerModel from '../components/container/ContainerModel';
import ElementModel from '../components/element/ElementModel';
import ExamModuleModel from '../components/exam-module/ExamModuleModel';

class Scorm {

	public successThreshold: 80;
	private module: ContainerModel;
	private exams: ExamModuleModel[];

	constructor( module ) {
		this.module = module;
	}

	public init(): boolean {
		this.exams = this.getExamModules( this.module );

		//load the examStates from Scorm
		window.addEventListener( 'message', e => {
			if( e.origin !== globalThis.origin ) return;
			if( e.data?.type !== 'scorm-initialize-from-suspend-data' ) return;
			const { suspendData } = e.data.data;
			console.log( 'Initializing module from received SCORM suspend data:', suspendData );
			this.restoreStateFromScormSuspendData( suspendData );
		} );
		
		return true;
	}

	private restoreStateFromScormSuspendData( suspendData: any[] ) {
		var hashmap = {};
		suspendData.forEach( exam => {
			hashmap[exam.id] = exam;
		});
		this.exams.forEach( exam => {
			if (hashmap[exam.id]) {
				exam.state = hashmap[exam.id].state;
				exam.currentAttempt = hashmap[exam.id].currentAttempt;
			}
		})
	}

	private getExamModules( element: ElementModel, exams = [] ): ExamModuleModel[] {
		
		if ( hasChildren( element ) ) {
			element.children.forEach( (child) => {
				this.getExamModules(child, exams);
			} );
		}
		if ( element instanceof ExamModuleModel ) {
			element.change.add( this.sendStateToScorm, this); //register event
			exams.push( element );
		}

		return exams;

		function hasChildren( element:ElementModel ): element is ElementModel & { children:ElementModel[] } {
			return element[ 'children' ]?.length > 0;
		}
	}

	private sendStateToScorm() {

		var correct = this.exams.filter( (exam)=> {
			return exam.state === 'success';
		})

		var failed = this.exams.filter( (exam)=> {
			return exam.state === 'failed';
		})

		//set open exams to disabled if there is a correct exam
		if (correct.length > 0) {
			this.exams.forEach( exam => {
				if (exam.state === 'open') {
					exam.state = 'disabled';
				}
			})
		}
		
		//set the examStates for scorm 1.2
		const suspendData = [];
		this.exams.forEach( exam => {
			var scormExam = {
				id: exam.id,
				state: exam.state,
				currentAttempt: exam.currentAttempt,
			}
			suspendData.push(scormExam);
		});

		let successStatus: SuccessStatus, scorePoints: number, completionStatus: CompletionStatus;

		if (correct.length > 0) {
			// at least one exam is correct
			successStatus = 'passed';
			scorePoints = 100;
			completionStatus = 'completed';
		}
		else if (failed.length === this.exams.length ) {
			//every exam is wrong
			successStatus = 'failed';
			scorePoints = 0;
			completionStatus = 'completed';
		}
		else {
			successStatus = 'unknown';
			scorePoints = 0;
			completionStatus = failed.length > 0 ? 'incomplete' : 'not attempted';
		}

		this.sendStatusToScorm( completionStatus, scorePoints, 100, successStatus, suspendData );
	}

	private sendStatusToScorm( completionStatus?: CompletionStatus, scorePoints?: number, scoreTotalPoints?: number, successStatus?: SuccessStatus, suspendData?: any ) {
		window.postMessage( {
			type: 'scorm-status',
			data: { completionStatus, scorePoints, scoreTotalPoints, successStatus, suspendData },
		}, window.origin !== 'null' ? window.origin : '*' );
	}
}

export default Scorm;
