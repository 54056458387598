import IoC from 'ln/ioc/IoC';
import type View from 'ln/view/View';

import type ElementModel from './ElementModel';
import type SlideModel from 'lf/slides/Slide/SlideModel';


type ElementModelRenderer = ( model:ElementModel|SlideModel ) => View;


class ElementModelRendererIoC extends IoC<ElementModelRenderer> {
}


export default ElementModelRendererIoC;
