import SubNavigationModel from './SubNavigationModel';
import Container from '../container/Container';
import ElementModelRendererIoC from '../element/ElementModelRendererIoC';


class SubNavigation extends Container {

    readonly model: SubNavigationModel;

    constructor(model: SubNavigationModel, elementsIoC: ElementModelRendererIoC) {
        super(model, 'lm.subnavigation-element', elementsIoC);
    }

    protected get elementContainerNode() {
        return this.node.js('elements');
    }
}


export default SubNavigation;
