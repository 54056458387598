import Container from '../container/Container';
import ContainerModel from '../container/ContainerModel';
import ElementModelRendererIoC from '../element/ElementModelRendererIoC';


class SubModule extends Container {

    constructor(model: ContainerModel, elementsIoC: ElementModelRendererIoC) {
        super(model, 'lm.sub-module', elementsIoC);
    }

    protected get elementContainerNode() {
        return this.node.js('elements');
    }
}


export default SubModule;
