import Model from 'ln/model/Model';

export type Direction = '' | 'n' | 's';

export default class ButtonTipModel extends Model {

    readonly top:number;
    readonly left:number;

    readonly color:string; // unused for now
    readonly label:string;
    readonly direction:Direction;

    readonly elements:Model[];

    constructor( obj:any ) {
        super( { elements: [], label: '', ...obj } );

        this.direction ||= 's';
    }
}
