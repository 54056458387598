import FoldableModel from './FoldableModel';
import Element from '../element/Element';


class Foldable extends Element {

    readonly model: FoldableModel;
    previewHeight: string;
    paddingBottom: number;

    constructor(model: FoldableModel) {
        super(model, 'lm.foldable-' + model.foldableStyle + '-element');

        //if preview modifier (foldableStyle) is set, this is the height of the body
        this.previewHeight = '225px';
    }

    init() {
        super.init();

        this.node.js( 'toggle' ).click.add( this.toggleFoldable, this );
    }

    toggleFoldable() {

        var bodyNode = this.node.js( 'foldable-body' );

        var targetBodyHeight = this.model.foldableStyle === 'preview' ? this.previewHeight : '0px';

        if ( bodyNode.native.style.maxHeight == '' || bodyNode.native.style.maxHeight == targetBodyHeight ) {
            this.node.js( 'foldable-element' ).addClass( '-expand' );
            bodyNode.native.style.maxHeight = bodyNode.native.scrollHeight  + 'px';
        } else {
            // set maxHeight to a pixel value in order to be transformed smoothly
            bodyNode.native.style.maxHeight = bodyNode.native.scrollHeight  + 'px';
            setTimeout( () => {
                bodyNode.native.style.maxHeight = targetBodyHeight;
                this.node.js( 'foldable-element' ).removeClass( '-expand' );
            }, 1);
        }
    }
}


export default Foldable;
