import ElementModel from '../element/ElementModel';


export default class LinkedVideoModel extends ElementModel {

    constructor(dto: Object) {
        super(dto);
    }

    /// Produces the URL for the <iframe>'s `src` attribute.
    public getEmbedUrl() {

        // This could be improved a lot, but it should do for now.

        const type = this.get<string>("type", "YouTube");
        const url = this.get<string>("url");

        if (url.indexOf('youtu.be/') >= 0) {
            const videoId = /https\:\/\/youtu\.be\/(.+)/;
            const match = url.match(videoId);
            if (match) {
                return `https://www.youtube.com/embed/${match[1]}`;
            }
        }
        else if (url.indexOf('dartfish.tv/') >= 0) {
            return url.replace('/Player?', '/Embed?')
                      .replace(/&V(W|H)=[0-9%]+/g, '') + '&VW=100%&VH=100%';
        }

        return url;
    }
}
