import Model from 'ln/model/Model';


class ElementModel extends Model {

    public parent:ElementModel;
    public progress;

    constructor(json: Object) {
        super(json);
    }

    get answered():boolean {
        return this.get('answered');
    }

    set answered( answered:boolean) {
        this.set('answered', answered);
    }

    get id(): string {
        return this.get<string>('id');
    }

    get modelName(): string {
        return this.get<string>('modelName');
    }

    //get the outermost Module
    get rootModule():ElementModel {
        if ( this.parent == null ) return this;
        return this.parent.rootModule;
    }

    get uid(): string {
        if (super.object().uid) return super.object().uid + this.id;
        var prefix = this.modelName.substring(0, 2);
        return prefix + this.id;
    }

    object() {
        var obj: any = super.object();
        if (!obj.uid) obj.uid = this.uid;
        return obj;
    }
}

export default ElementModel;