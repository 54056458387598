import DownloadModel from './DownloadModel';
import Element from '../element/Element';


class Download extends Element {

    readonly model: DownloadModel;

    constructor(model: DownloadModel) {
        super(model, 'lm.download-element');
    }
}


export default Download;
