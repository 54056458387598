
	import Template from 'ln/template/TemplateManager';
	Template.add( { lf:{
  "lernfragen": "<div view=\"slide-renderer\" class=\"slide-container  text-style -p\"></div>\t\t<div id=\"feedback-placeholder\" class=\"feedback-placeholder\"></div>",
  "feedback-panel": "<div js=\"feedback-container\" class=\"feedback-panel feedback-box \"><div class=\"feedback-panel padding-box -all-medium text-style -p\">[[= content ]]</div></div>",
  "feedback-button": "<div class=\"feedback-container\"><button js=\"feedback-button\" class=\"button-style -feedback text-style -h5\">[[ this.trans('feedback')]]</button></div>",
  "continue-button": "<button js=\"continue-button\" class=\"button-style -action text-style -h5\">[[ this.trans('continue-video')]]</button>",
  "back-button": "<button js=\"back-button\" class=\"button-style -back text-style -h5\">[[ this.trans('repeat-chapter')]]</button>",
  "exam-button": "<div class=\"exam-feedback element-layout -read margin-box -bottom-medium\"><button js=\"exam-button\" class=\"button-style -action text-style -h5\">[[ this.trans('submit-exam')]]</button><div js=\"feedback\"></div></div>",
  "exam-feedback": "<div class=\"feedback-panel feedback-placeholder [[state]] text-style -p\">[[= feedback ]][% if (opt.certificate) { %][[ this.trans('certificate_download') ]] <a href=\"[[ this.downloadPath(certificate) ]]\" target=\"_self\"> [[ this.trans('download')]] </a>[% } %]</div>",
  "confirmationmail-container": "<div><div class=\"confirmationmail-container  margin-box -v-small\"><div class=\"form\"><div>[[ this.trans('mail.dialog')]]</div><input type=\"text\" placeholder=\"E-Mail\" class=\"email text-style -h5 margin-box -right-small -top-small\"><div class=\"error-message -invalid-syntax -hidden margin-box -top-small\">[[ this.trans('mail.syntax-error')]]</div><input type=\"text\" placeholder=\"E-Mail zur Best&#xE4;tigung nochmals eingeben\" class=\"email -repeat text-style -h5 margin-box -right-small -top-small\"><div class=\"error-message -emails-do-not-match -hidden margin-box -top-small\">[[ this.trans('mail.emails-do-not-match')]]</div><div class=\"margin-box -top-small\"><button class=\"button-style -action text-style -h5\" js=\"send-email\">[[ this.trans('mail.button')]]</button></div></div><div class=\"success-message -hidden margin-box -top-small\">[[ this.trans('mail.success')]]</div></div></div>"
} } );
