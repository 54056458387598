import ContainerModel from '../container/ContainerModel';


//an event can hav multiple slides, but in reality only has one ... (sc/mc...). still needs to be a containermodel and not an element model
class SinglePageModel extends ContainerModel {

    constructor( json ) {
          super( json );
    }

    get cover(): string {
        return this.get('cover');
    }

    get showInFooter(): boolean {
        return this.get( 'showInFooter', true );
    }

    get showInMenu(): boolean {
        return this.get( 'showInFooter', true );
    }
}
export default SinglePageModel;
