import View from 'ln/view/View';

import ExamModuleModel from '../exam-module/ExamModuleModel';
import SubModuleExamView from '../exam-module/SubModuleExam';
import ProgressModuleModel from '../progress-module/ProgressModuleModel';
import SubModuleView from '../progress-module/SubModule';
import SubNavigationModel from '../sub-navigation/SubNavigationModel';
import type ContainerModel from '../container/ContainerModel';
import type ElementModel from '../element/ElementModel';
import type ElementModelRendererIoC from '../element/ElementModelRendererIoC';
import { navigationService } from '../../services/NavigationService';

export default class PreviousNextNavigation extends View {

    private subModules: ElementModel[];

    public constructor( private readonly model: ContainerModel, private readonly elementsIoC: ElementModelRendererIoC ) {
        super( { template: 'lm.previous-next-navigation' } );

        this.subModules = this.getSubModules();
    }

    init() {
        const previousLink = this.node.js( 'previous' );
        previousLink.click.add( () => {
            if( previousLink.hasClass( '-disabled' ) ) return;

            const index = this.getIndex();
            if( index > 0 ) {
                const previousSubModule = this.subModules[ index - 1 ];
                const previousSubModuleView = this.getViewFor( previousSubModule ).render();
                navigationService.navigateTo( previousSubModuleView );
            }
            else {
                navigationService.navigateToHome();
            }
        } );

        const nextLink = this.node.js( 'next' );
        nextLink.click.add( () => {
            if( nextLink.hasClass( '-disabled' ) ) return;

            const index = this.getIndex();
            const nextSubModule = this.subModules[ index + 1 ];
            const nextSubModuleView = this.getViewFor( nextSubModule ).render();
            navigationService.navigateTo( nextSubModuleView );
        } );

        this.update();
        navigationService.navigated.add( () => this.update() );
    }

    private update() {
        const subModules = this.getSubModules();
        const index = this.getIndex();

        const canNavigateToPrevious = index >= 0 && this.isOpen( index - 1 );
        const previousLink = <HTMLButtonElement> this.node.js( 'previous' ).native;
        previousLink.disabled = !canNavigateToPrevious;
        previousLink.hidden = index < 0;

        let previousLabel: string;
        if( index > 0 ) {
            previousLabel = subModules[ index - 1 ].get( 'title' );
        }
        else if( index === 0 ) {
            previousLabel = this.model.rootModule.get( 'title' );
        }
        else {
            previousLabel = '';
        }
        const previousLabelNode = this.node.js( 'previousLabel' );
        previousLabelNode.native.textContent = previousLabel;

        const canNavigateToNext = index < this.subModules.length - 1 && this.isOpen( index + 1 );
        const nextLink = <HTMLButtonElement> this.node.js( 'next' ).native;
        nextLink.disabled = !canNavigateToNext;
        nextLink.hidden = index >= this.subModules.length - 1;

        let nextLabel: string;
        if( index < subModules.length - 1 ) {
            nextLabel = subModules[ index + 1 ].get( 'title' );
        }
        else {
            nextLabel = '';
        }
        const nextLabelNode = this.node.js( 'nextLabel' );
        nextLabelNode.native.textContent = nextLabel;
    }

    private getIndex(): number {
        return this.subModules.indexOf( navigationService.getLocation()?.data );
    }

    private isOpen( index: number ): boolean {
        if( index === -1 ) return true;
        const subModule = this.subModules[ index ];
        return subModule instanceof ExamModuleModel ? subModule.state === 'open' : true;
    }

    // TODO: The following two methods are similar to code already found in `Menu`. Consider refactoring towards less code duplication.

    private getViewFor( subModule: ElementModel ): View {
        if( subModule instanceof ProgressModuleModel ) {
            return new SubModuleView( subModule, this.elementsIoC );
        }
        if ( subModule instanceof ExamModuleModel ) {
            return new SubModuleExamView( subModule, this.elementsIoC );
        }
    }

    private getSubModules(): ElementModel[] {
        var modules = [];
        for( const element of this.model.elements ) {
            if ( element instanceof SubNavigationModel ) {
                modules.push( ...element.elements );
            }
        }
        return modules;
    }
}
