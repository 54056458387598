import VideoModel from './VideoModel';
import Element from '../element/Element';


class Video extends Element {

    readonly model: VideoModel;

    constructor(model: VideoModel) {
        super(model, 'lm.video-element');
    }
}


export default Video;
