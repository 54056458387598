import Element from '../element/Element';

import type FlipCardsModel from './FlipCardsModel';

export default class FlipCards extends Element {

    readonly model: FlipCardsModel;

    constructor( model: FlipCardsModel ) {
        super( model, 'lm.flip-cards-element' );
    }

    init() {
        super.init();

        for( const card of this.node.all( '.flip-card' ) ) {
            card.click.add( () => {
                card.toggleClass( '-flipped' );
            } );
        }
    }
}
