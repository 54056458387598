import IoC from 'ln/ioc/IoC';
import ListRenderer from 'ln/list/ListRenderer';
import Node from 'ln/node/Node';
import { Signal } from 'ln/signal/Signal';
import Template from 'ln/template/TemplateManager';
import View from 'ln/view/View';

import ContainerModel from '../container/ContainerModel';
import ElementModel from '../element/ElementModel';
import ElementModelRendererIoC from '../element/ElementModelRendererIoC';
import type ExamModuleModel from '../exam-module/ExamModuleModel';
import type ProgressModuleModel from '../progress-module/ProgressModuleModel';
import SubModuleExamView from '../exam-module/SubModuleExam';
import SubModuleView from '../progress-module/SubModule';
import type SubNavigationModel from '../sub-navigation/SubNavigationModel';
import SinglePageView from '../single-page/SinglePage';
import type SinglePageModel from '../single-page/SinglePageModel';
import { navigationService } from '../../services/NavigationService';


/** `View` class for the main menu. */
export default class Menu extends View {

    private readonly model: ContainerModel;
    private readonly singlePages: SinglePageModel[];
    private readonly flat: boolean;
    //on module clicked
    public selected:Signal = new Signal();

    /** Initializes a new instance of the `Menu` class.
     * @param model The `ContainerModel` for whose elements the menu will display items.
     * @param flat If `true`, the menu will not display sub-items.
     */
    constructor(model: ContainerModel, singlePages: SinglePageModel[], private readonly elementsIoC: ElementModelRendererIoC, flat: boolean = false) {
        super({ template: 'lm.menu' });

        this.model = model;
        this.singlePages = singlePages;
        this.flat = flat;
    }

    protected init() {
        const elementsNode = this.node.js( 'elements' );
        elementsNode.empty();
        const ioc = new MenuItemIoC(this, this.elementsIoC);
        const renderer = new MenuItemRenderer(this, ioc);
        renderer.renderItems( elementsNode, this.getElements() );
        renderer.renderItems( elementsNode, this.getSinglePages() );
    }

    private getElements(): ElementModel[] {
        if (this.flat) {
            var modules = [];
            this.model.elements.forEach( (element : ElementModel  ) => {
                if (element.modelName === 'App\\SubNavigation') {
                    (element as ContainerModel).elements.forEach( (module) => {
                        modules.push( module );
                    });
                }
            });
            return modules;
        }
        else {
            this.model.get( 'elements' );
        }
    }

    private getSinglePages(): SinglePageModel[] {
        return this.singlePages.filter(singlePage => singlePage.showInMenu);
    }

    get menu():Menu {
        return this;
    }
}


/** Abstract `View`-derived base class that represents a menu item. */
abstract class MenuItem extends View {

    menu: Menu;

    protected constructor(menu: Menu) {
        super();
        this.menu = menu;
    };
}


/** `View` class that represents a menu item for a `App\SinglePage` model. */
class SinglePageMenuItem extends MenuItem {

    private readonly model: SinglePageModel;

    constructor(model: SinglePageModel, menu: Menu, private readonly elementsIoC: ElementModelRendererIoC) {
        super(menu);
        this.defaultTemplate = 'lm.menu-singlepage';
        this.model = model;
    }

    protected init() {
        this.node.js('select').click.add(() => this.onSelected());
    }

    protected renderData() {
        return this.model;
    }

    private onSelected() {
        this.menu.selected.dispatch( this );
        const singlePageView = new SinglePageView(this.model, this.elementsIoC).render();
        navigationService.navigateTo(singlePageView);
    }
}


/** `View` class that represents a sub-menu menu item for an `App\SubNavigation` model. */
class SubMenuItem extends MenuItem {

    private model: ContainerModel;

    constructor(model, menu: Menu, private readonly ioc: MenuItemIoC) {
        super(menu);
        this.defaultTemplate = 'lm.menu-subnavi';
        this.model = model;
    }

    protected init() {
        const renderer = new MenuItemRenderer(this, this.ioc);
        renderer.renderItems(this.node.js('elements'), this.model.get('elements'));

        this.node.js('toggle').click.add( ()=> {
            this.node.js('toggle').parent().toggleClass('-open');
        })
    }

    protected renderData() {
        return this.model;
    }
}



/** `View` class that represents a menu item for an `App\ProgressModule` model. */
class ProgressModuleMenuItem extends MenuItem {

    private readonly model: ContainerModel;
    progressChartTemplate: string;

    /**
     * @param model The `ContainerModel` for which to create a menu item.
     */
    constructor(model: ContainerModel, menu: Menu, private readonly elementsIoC: ElementModelRendererIoC) {
        super(menu);
        this.defaultTemplate = 'lm.menu-module';
        this.progressChartTemplate = 'lm.progress-chart';
        this.model = model;
    }

    protected init() {
        this.node.js('select').click.add( this.onSubmoduleSelect, this);
        this.renderProgress();
    }
    protected onSubmoduleSelect( module ) {
        this.menu.selected.dispatch( this );
        var details = new SubModuleView(this.model, this.elementsIoC).render();
        navigationService.navigateTo(details);
        this.model.rootModule.change.dispatch();
    }

    protected renderProgress() {
        var chart = Node.fromHTML( Template.render(this.progressChartTemplate, this.model ) );
        this.node.js('progress-chart').html = chart.html;

    }

    protected renderData() {
        return this.model;
    }
}


/** `View` class that represents a menu item for an `App\ExamModule` model. */
class ExamModuleMenuItem extends MenuItem {

    listrenderer: MenuItemRenderer;
    examStateTemplate: string;

    constructor(private readonly model: ExamModuleModel, menu: Menu, private readonly elementsIoC: ElementModelRendererIoC) {
        super(menu);
        this.defaultTemplate = 'lm.menu-exammodule';
        this.examStateTemplate = 'lm.exam-state';
    }

    protected init() {

        this.node.js('select').click.add( this.onSubmoduleSelect, this);
        this.renderProgress();
    }
    protected onSubmoduleSelect( module ) {
        //todo style states etc
        if (this.model.state === 'open') {
            this.menu.selected.dispatch( this );
            var details = new SubModuleExamView(this.model, this.elementsIoC).render();
            navigationService.navigateTo(details);
            this.model.rootModule.change.dispatch();
        }

    }

    protected renderProgress() {
        var state = Node.fromHTML( Template.render(this.examStateTemplate, this.model ) );
        this.node.js('exam-state').html = state.html;
        if (this.model.state != 'open') {
            this.node.js('select').click.removeAll();
        }

    }

    protected renderData() {
        return this.model;
    }
}


class MenuItemRenderer {

    container: View;

    private readonly ioc: MenuItemIoC;

    constructor(container: View, ioc: MenuItemIoC) {
        this.container = container;
        this.ioc = ioc;
   }

    renderItems( node:Node, models:Array<any> ) {

        const listRenderer = new ListRenderer<any>();
        listRenderer.ioc = this.ioc;
        listRenderer.selectorFunction = (data) => data.modelName;

        listRenderer.container = node;
        listRenderer.source.fill( models );
    }
}


class MenuItemIoC extends IoC<(element: ElementModel) => MenuItem> {

    private readonly menu: Menu;

    constructor(menu: Menu, private readonly elementsIoC: ElementModelRendererIoC) {
        super();

        this.menu = menu;

        this.add(
            'App\\SubNavigation',
            ( element:SubNavigationModel ) => new SubMenuItem(element, this.menu, this).render());

        this.add(
            'App\\ProgressModule',
            ( element:ProgressModuleModel ) => new ProgressModuleMenuItem(element as ContainerModel, this.menu, this.elementsIoC).render());

        this.add(
            'App\\ExamModule',
            ( element:ExamModuleModel ) => new ExamModuleMenuItem(element, this.menu, this.elementsIoC).render());

        this.add(
            'App\\SinglePage',
            ( singlePage:SinglePageModel ) => new SinglePageMenuItem(singlePage as SinglePageModel, this.menu, this.elementsIoC).render());

        // hack for testing
        this.add(
            'default',
            element => <MenuItem> { node: Node.fromHTML('') });
    }
}
