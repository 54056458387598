import Element from '../element/Element';
import ElementModelRendererIoC from '../element/ElementModelRendererIoC';
import type ButtonTooltipModel from './ButtonTooltipModel';
import type ButtonTipModel from './ButtonTipModel';
import List from 'ln/list/List';
import ListRenderer from 'ln/list/ListRenderer';
import type Model from 'ln/model/Model';

export default class ButtonTooltip extends Element {

    data:ButtonTooltipModel;

    constructor( model:ButtonTooltipModel, private readonly elementsIoC:ElementModelRendererIoC ) {
        super( model, 'lm.button-tooltip-element' );
    }

    init() {
        this.data.tooltips?.forEach( ( tip, index ) => {
            const buttonNode = this.node.js( 'button-' + index );
            const tooltipNode = this.node.js( 'tooltip-' + index );

            const renderer = new ListRenderer<Model>( tooltipNode );
            renderer.ioc = this.elementsIoC;
            renderer.source = new List<Model>( tip.elements );

            buttonNode.click.add( () => this.toggleTipVisibility( tip, buttonNode.native, tooltipNode.native ) );
        } );
    }

    private toggleTipVisibility( tip:ButtonTipModel, buttonNode:HTMLElement, tooltipNode:HTMLElement ) {
        this.setTipVisibility( tip, tooltipNode.classList.contains( '-hidden' ), buttonNode, tooltipNode );
    }

    private setTipVisibility( tip:ButtonTipModel, visible:boolean, buttonNode:HTMLElement, tooltipNode:HTMLElement ) {
        tooltipNode.classList.toggle( '-hidden', !visible );

        if( visible ) {
            // position tooltip relative to image:
            const parentNode = this.node.js( 'tooltips' ).native;
            const buttonBounds = buttonNode.getBoundingClientRect();
            const parentBounds = parentNode.getBoundingClientRect();

            if( !buttonBounds.width || !parentBounds.width ) return;

            const width = ( 0.66 * parentBounds.width );
            let left = buttonBounds.left + buttonBounds.width / 2 - parentBounds.left - width / 2;
            if( left + width + 8 > parentBounds.width ) left = parentBounds.width - width - 8;
            if( left < 8 ) left = 8;
            tooltipNode.style.width = width + 'px';
            tooltipNode.style.left = left + 'px';
            if( tip.direction === 'n' ) {
                tooltipNode.style.bottom = ( parentBounds.bottom - buttonBounds.top ) + 'px';
            }
            else {
                tooltipNode.style.top = ( buttonBounds.bottom - parentBounds.top ) + 'px';
            }

            // clicks outside should close tooltip:
            const closeHandler = ( e: MouseEvent ) => {
                if( !tooltipNode.contains( <HTMLElement> e.target ) && !buttonNode.contains( <HTMLElement> e.target ) ) {
                    this.setTipVisibility( tip, false, buttonNode, tooltipNode );
                    document.removeEventListener( 'click', closeHandler );
                }
            };
            document.addEventListener( 'click', closeHandler, { capture: true } );
        }
        else {
            tooltipNode.style.left = '';
            tooltipNode.style.top = '';
        }
    }
}
