import Mapper from 'ln/model/Mapper';

import ClozeModel from 'lf/slides/Cloze/ClozeModel';
import DragDropModel from 'lf/slides/DragDrop/DragDropModel';
import DropDownModel from 'lf/slides/DropDown/DropDownModel';
import FreeTextModel from 'lf/slides/FreeText/FreetextModel';
import HotspotModel from 'lf/slides/HotSpot/HotspotModel';
import MultipleChoiceModel from 'lf/slides/MultipleChoice/MultipleChoiceModel';
import SingleChoiceModel from 'lf/slides/SingleChoice/SingleChoiceModel';
import TooltipModel from 'lf/slides/ToolTip/TooltipModel';

import AudioVideoModel from '../components/audio-video/AudioVideoModel';
import ButtonTipModel from '../components/button-tooltip/ButtonTipModel';
import ButtonTooltipModel from '../components/button-tooltip/ButtonTooltipModel';
import CompositeArticleModel from '../components/composite-article/CompositeArticleModel';
import CompositeMaterialModel from '../components/composite-material/CompositeMaterialModel';
import DownloadModel from '../components/download/DownloadModel';
import ElementModel from '../components/element/ElementModel';
import ExamModuleModel from '../components/exam-module/ExamModuleModel';
import FlipCardsModel from '../components/flip-cards/FlipCardsModel';
import FoldableModel from '../components/foldable/FoldableModel';
import ImageModel from '../components/image/ImageModel';
import LernVideoModel from '../components/lernvideo/LernVideoModel';
import VideoChapterModel from '../components/lernvideo/VideoChapterModel';
import VideoEventModel from '../components/lernvideo/VideoEventModel';
import LinkModel from '../components/link/LinkModel';
import LinkedVideoModel from '../components/linked-video/LinkedVideoModel';
import ModuleModel from '../components/module/ModuleModel';
import ParagraphModel from '../components/paragraph/ParagraphModel';
import ProgressModuleModel from '../components/progress-module/ProgressModuleModel';
import SinglePageModel from '../components/single-page/SinglePageModel';
import SubNavigationModel from '../components/sub-navigation/SubNavigationModel';
import TitleModel from '../components/title/TitleModel';
import VideoModel from '../components/video/VideoModel';


var mapper = new Mapper();

mapper.toModel.add(
    'App\\AudioVideo',
    dto => new AudioVideoModel(dto));

mapper.toModel.add(
    'App\\Cloze',
    dto => new ClozeModel(dto));

mapper.toModel.add(
    'App\\CompositeArticle',
    dto => new CompositeArticleModel(dto));

mapper.toModel.add(
    'App\\CompositeMaterial',
    dto => new CompositeMaterialModel(dto));

mapper.toModel.add(
    'App\\Download',
    dto => new DownloadModel(dto));

mapper.toModel.add(
    'App\\ExamModule',
    dto => new ExamModuleModel(dto));

mapper.toModel.add(
    'App\\FlipCards',
    dto => new FlipCardsModel(dto));

mapper.toModel.add(
    'App\\Foldable',
    dto => new FoldableModel(dto));

mapper.toModel.add(
    'App\\Image',
    dto => new ImageModel(dto));

mapper.toModel.add(
    'App\\LernVideo',
    dto => new LernVideoModel(dto));

mapper.toModel.add(
    'App\\Link',
    dto => new LinkModel(dto));

mapper.toModel.add(
    'App\\LinkedVideo',
    dto => new LinkedVideoModel(dto));

mapper.toModel.add(
    'App\\Module',
    dto => new ModuleModel(dto));

mapper.toModel.add(
    'App\\Paragraph',
    dto => new ParagraphModel(dto));

mapper.toModel.add(
    'App\\ProgressModule',
    dto => new ProgressModuleModel(dto));

mapper.toModel.add(
    'App\\SubNavigation',
    dto => new SubNavigationModel(dto));

mapper.toModel.add(
    'App\\Title',
    dto => new TitleModel(dto));

mapper.toModel.add(
    'App\\Video',
    dto => new VideoModel(dto));

mapper.toModel.add(
    'App\\VideoChapter',
    dto => new VideoChapterModel(dto));

mapper.toModel.add(
    'App\\VideoEvent',
    dto => new VideoEventModel(dto));

mapper.toModel.add( 'App\\SingleChoice', function ( json ) {
    return new SingleChoiceModel( json );
});

mapper.toModel.add( 'App\\MultipleChoice', function ( json ) {
    return new MultipleChoiceModel( json );
});
mapper.toModel.add( 'App\\DropDown', function ( json ) {
    return new DropDownModel( json );
});
mapper.toModel.add( 'App\\DragDrop', function ( json ) {
    return new DragDropModel( json );
});
mapper.toModel.add( 'App\\FreeText', function ( json ) {
    return new FreeTextModel( json );
});

mapper.toModel.add( 'App\\SinglePage', function ( json ) {
    return  new SinglePageModel( json ) ;
});

mapper.toModel.add('App\\Tooltip', function(json) {
    return new TooltipModel(json);
});

mapper.toModel.add('App\\ButtonTooltip', function(json) {
    return new ButtonTooltipModel(json);
});

mapper.toModel.add('App\\ButtonTip', function(json) {
    return new ButtonTipModel(json);
});

mapper.toModel.add('App\\Hotspot', function(json) {
    return new HotspotModel(json);
});

//dont map images
mapper.toModel.add( 'App\\Asset', function ( json ) {
    return json;
});

//dont map
mapper.toModel.add( 'App\\ChoiceAnswer', function ( json ) {
    return json;
});
mapper.toModel.add( 'App\\Drag', function ( json ) {
    return json;
});
mapper.toModel.add( 'App\\Drop', function ( json ) {
    return json;
});
mapper.toModel.add('App\\Tip', function(json) {
    return json;
});
mapper.toModel.add('App\\HotspotArea', function(json) {
    return json;
});



//LernBuch Models
mapper.toModel.add( 'default', function ( json ) {
    return new ElementModel( json );
});

export default mapper;