import { shuffle } from 'ln/js';

import ElementModel from '../element/ElementModel';

type FlipCardModel = object;

export default class FlipCardsModel extends ElementModel {

    public constructor( json:object ) {
        super( json );

        if( this.shuffle ) {
            this.cards = shuffle( this.cards );
        }
    }

    public get cards(): FlipCardModel[] {
        return this.get( 'cards', [] );
    }

    private set cards( value:FlipCardModel[] ) {
        this.set( 'cards', value );
    }

    public get shuffle(): boolean {
        return this.get( 'shuffle', false );
    }
}
