import Node from 'ln/node/Node';
import View from 'ln/view/View';
import setup from 'ln/setup/setup';
import Template from 'ln/template/TemplateManager';
import List from 'ln/list/List';
import ListRenderer from 'ln/list/ListRenderer';

import ModuleModel from './ModuleModel';
import Container from '../container/Container';
import ElementModelRendererIoC from '../element/ElementModelRendererIoC';
import Menu from '../menu/Menu';
import PreviousNextNavigation from '../previous-next-navigation/PreviousNextNavigation';
import SinglePage from '../single-page/SinglePage';
import SinglePageModel from '../single-page/SinglePageModel';
import Scorm from '../../lernfragen/Scorm';
import { navigationService } from '../../services/NavigationService';


class Module extends Container {
	
	model: ModuleModel;
	singlepage: SinglePageModel[];
	tartetNode:Node;
	menu: Menu;

	constructor(model: ModuleModel, singlepage: SinglePageModel[], elementsIoC: ElementModelRendererIoC) {
		super(model, 'lm.module', elementsIoC);
		this.singlepage = singlepage;
	}

	protected get elementContainerNode() {
		return this.node.js('elements');
	}

	private get homeButton() {
		return this.node.js('home-button');
	}

	init() {
		
		//target node for content
		navigationService.renderTarget = this.node.one('#node-stack');

		// home button:
		this.homeButton.click.add(() => this.navigateToHome());
		navigationService.navigated.add(() => this.updateHomeButtonVisibility());

		//menu
		this.menu = new Menu(this.model, this.singlepage, this.elementsIoC, true);
		const menuButton = this.node.js('menu-hamburger');
		menuButton.click.add( function() {
			this.node.js('sidebar').toggleClass('-hide');
			menuButton.parent().toggleClass('-open');
		}.bind(this));



		 this.menu.selected.add( function( menuItem ) {
			this.node.js('sidebar').toggleClass('-hide');
			this.node.js('menu-hamburger').parent().toggleClass('-open');
			//re-render menu
			this.menu.render( this.node.js('main-navigation') );
		}.bind(this));

		// previous/next navigation
		const previousNextNavigation = new PreviousNextNavigation( this.model, this.elementsIoC );
		previousNextNavigation.render( this.node.js( 'previous-next-navigation' ) );

		//language switcher
		if ( setup.data().localisation ) {
			var languageSwitcher = Node.fromHTML( Template.render( 'lm.language-switcher',  { locales: setup.data('localisation') } ) );
			const languageSwitcherNode = this.node.js('language-switcher');
			languageSwitcherNode.html = languageSwitcher.html;
			languageSwitcherNode.click.add( () => {
				languageSwitcherNode.toggleClass( '-expanded' );
			} );
		}

		// alternative footer logos
		if( this.data.footer ) {
			const logos = this.node.js( 'footerLogos' );
			const renderer = new ListRenderer( logos );
			renderer.defaultRender( logo => {
				const footerLogoView = new View( logo );
				footerLogoView.defaultTemplate = 'lm.footer-logo';
				return { node: footerLogoView.render().node };
			} );
			renderer.source = new List( this.data.footer.logos );
		}

		//single page links
		if ( this.singlepage ) {
			this.singlepage.forEach( ( page ) => {
				var link = Node.fromHTML( Template.render('lm.singlepage-link', { opt:page.object() }));
				link.click.add( () => {
					var singlepage = new SinglePage(page, this.elementsIoC).render();
					navigationService.navigateTo(singlepage);
				})
				if (page.showInFooter) {
					this.node.js('singlepage-footer-link').append( link );
				}
			});
		}

		// render content (sub-elements)
		super.init();

		this.model.change.add( function( e ) {
			this.update( e );
		}.bind(this));

		this.model.change.dispatch();

		// enable SCORM
		const scorm = new Scorm( this.model );
		scorm.init(); // init for timetracking

		const locales = setup.data().localisation;
		if ( locales?.length ) {
			var languageSwitcher = Node.fromHTML( Template.render( 'lm.language-switcher',  { locales } ) );
			this.node.js('language-switcher').html = languageSwitcher.html;
		}
	}


    public update() {
		this.menu.render( this.node.js('main-navigation') );
		this.updateHomeButtonVisibility();
	}

	private updateHomeButtonVisibility() {
		this.homeButton.style.visibility = navigationService.isAtHome() ? 'collapse' : 'visible';
	}

	public navigateToHome() {
		navigationService.navigateToHome();
		this.model.change.dispatch();
	}
}


export default Module;
