import LinkModel from './LinkModel';
import Element from '../element/Element';


class Link extends Element {

    readonly model: LinkModel;

    constructor(model: LinkModel) {
        super(model, 'lm.link-element');
    }
}


export default Link;
