import View from 'ln/view/View';

import ElementModelRendererIoC from '../components/element/ElementModelRendererIoC';

import AudioVideo from '../components/audio-video/AudioVideo';
import type AudioVideoModel from '../components/audio-video/AudioVideoModel';

import ButtonTooltip from '../components/button-tooltip/ButtonTooltip';
import type ButtonTooltipModel from '../components/button-tooltip/ButtonTooltipModel';

import CompositeArticle from '../components/composite-article/CompositeArticle';
import type CompositeArticleModel from '../components/composite-article/CompositeArticleModel';

import CompositeMaterial from '../components/composite-material/CompositeMaterial';
import type CompositeMaterialModel from '../components/composite-material/CompositeMaterialModel';

import Download from '../components/download/Download';
import type DownloadModel from '../components/download/DownloadModel';

import ExamModule from '../components/exam-module/ExamModule';
import type ExamModuleModel from '../components/exam-module/ExamModuleModel';

import FlipCards from '../components/flip-cards/FlipCards';
import type FlipCardsModel from '../components/flip-cards/FlipCardsModel';

import Foldable from '../components/foldable/Foldable';
import type FoldableModel from '../components/foldable/FoldableModel';

import Image from '../components/image/Image';
import type ImageModel from '../components/image/ImageModel';

import LernVideo from '../components/lernvideo/LernVideo';
import type LernVideoModel from '../components/lernvideo/LernVideoModel';

import Link from '../components/link/Link';
import type LinkModel from '../components/link/LinkModel';

import LinkedVideo from '../components/linked-video/LinkedVideo';
import type LinkedVideoModel from '../components/linked-video/LinkedVideoModel';

import Paragraph from '../components/paragraph/Paragraph';
import type ParagraphModel from '../components/paragraph/ParagraphModel';

import ProgressModule from '../components/progress-module/ProgressModule';
import type ProgressModuleModel from '../components/progress-module/ProgressModuleModel';

import Quiz from '../components/quiz/Quiz';
import type ClozeModel from 'lf/slides/Cloze/ClozeModel';
import type DragDropModel from 'lf/slides/DragDrop/DragDropModel';
import type DropDownModel from 'lf/slides/DropDown/DropDownModel';
import type FreeTextModel from 'lf/slides/FreeText/FreetextModel';
import type HotspotModel from 'lf/slides/HotSpot/HotspotModel';
import type MultipleChoiceModel from 'lf/slides/MultipleChoice/MultipleChoiceModel';
import type SingleChoiceModel from 'lf/slides/SingleChoice/SingleChoiceModel';

import SubNavigation from '../components/sub-navigation/SubNavigation';
import type SubNavigationModel from '../components/sub-navigation/SubNavigationModel';

import Title from '../components/title/Title';
import type TitleModel from '../components/title/TitleModel';

import Tooltip from '../components/tooltip/Tooltip';
import type TooltipModel from '../components/tooltip/TooltipModel';

import Video from '../components/video/Video';
import type VideoModel from '../components/video/VideoModel';

const elementsIoC = new ElementModelRendererIoC();

elementsIoC.add(
	'App\\AudioVideo',
	(model:AudioVideoModel) => new AudioVideo(model).render());

elementsIoC.add(
	'App\\ButtonTooltip',
	(model:ButtonTooltipModel) => new ButtonTooltip(model, elementsIoC).render());

elementsIoC.add(
	'App\\Cloze',
	( model:ClozeModel ) => new Quiz(model).render());

elementsIoC.add(
	'App\\CompositeArticle',
	(model: CompositeArticleModel ) => new CompositeArticle(model, elementsIoC).render());

elementsIoC.add(
	'App\\CompositeMaterial',
	(model: CompositeMaterialModel ) => new CompositeMaterial(model, elementsIoC).render());

elementsIoC.add(
	'App\\Download',
	( model:DownloadModel ) => new Download(model).render());

elementsIoC.add(
	'App\\DragDrop',
	( model:DragDropModel ) => new Quiz(model).render());

elementsIoC.add(
	'App\\DropDown',
	( model:DropDownModel ) => new Quiz(model).render());

elementsIoC.add(
	'App\\ExamModule',
	(model: ExamModuleModel) => new ExamModule(model, elementsIoC).render());

elementsIoC.add(
	'App\\FlipCards',
	(model:FlipCardsModel) => new FlipCards(model).render());

elementsIoC.add(
	'App\\Foldable',
	( model:FoldableModel ) => new Foldable(model).render());

elementsIoC.add(
	'App\\FreeText',
	( model:FreeTextModel ) => new Quiz(model).render());

elementsIoC.add(
	'App\\Hotspot',
	( model:HotspotModel ) => new Quiz(model).render());

elementsIoC.add(
	'App\\Image',
	( model:ImageModel ) => new Image(model).render());

elementsIoC.add(
	'App\\LernVideo',
	( model:LernVideoModel ) => new LernVideo(model).render());

elementsIoC.add(
	'App\\Link',
	( model:LinkModel ) => new Link(model).render());

elementsIoC.add(
	'App\\LinkedVideo',
	( model:LinkedVideoModel ) => new LinkedVideo(model).render());

elementsIoC.add(
	'App\\MultipleChoice',
	( model:MultipleChoiceModel ) => new Quiz(model).render());

elementsIoC.add(
	'App\\Paragraph',
	( model:ParagraphModel ) => new Paragraph(model).render());

elementsIoC.add(
	'App\\ProgressModule',
	( model:ProgressModuleModel ) => new ProgressModule(model, elementsIoC).render());

elementsIoC.add(
	'App\\SingleChoice',
	( model:SingleChoiceModel ) => new Quiz(model).render());

elementsIoC.add(
	'App\\SubNavigation',
	(model: SubNavigationModel ) => new SubNavigation(model, elementsIoC).render());

elementsIoC.add(
	'App\\Title',
	( model:TitleModel ) => new Title(model).render());

elementsIoC.add(
	'App\\Tooltip',
	( model:TooltipModel ) => new Tooltip(model).render());

elementsIoC.add(
	'App\\Video',
	( model:VideoModel ) => new Video(model).render());


elementsIoC.add('default', model => {
	console.error(`No renderer configured for model of type '${model.modelName}'.`, {model});
	return new View(model).render();
});


export default elementsIoC;
