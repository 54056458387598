import ElementModel from '../element/ElementModel';
import type ButtonTipModel from './ButtonTipModel';

export default class ButtonTooltipModel extends ElementModel {

    image:Asset;
    text:string;
    title:string;
    tooltips:ButtonTipModel[];

    public constructor( json?:any ) {
        super( json );
    }
}

interface Asset {
    file_name:string;
    ext?:string;
}
