import QuestionModel from 'lf/slides/Question/QuestionModel';

import ElementModel from '../element/ElementModel';


class ContainerModel extends ElementModel {

    constructor( json ) {
		  super( json );
    }

    get title(): string {
        return this.get('title');
    }
   
    get elements():ElementModel[] {
        return this.get( 'elements', []) as ElementModel[];
    }

    get children():ElementModel[] {
        return this.elements;
    }

    //progress for quiz elements (property answered)
    get answered():boolean {
        return this.isAnswered;
    }

    //count elements with property 'answered'. inclusive containers
    get totalQuestions():number {
        return this.getTotalQuestions();
    }

    protected getTotalQuestions() {
        let count = 0;
        for (const element of this.elements) {
            if (element instanceof QuestionModel) {
                // For LernFragen `QuestionModel`s, we assume that they represent 1 question each.
                count += 1;
            }
            else if (element instanceof ContainerModel) {
                // For LernModul `ContainerModel`s, we assume that they may contain sub-questions.
                // Therefore we use recursion here:
                count += element.getTotalQuestions();
            }
        }
        return count;
    }

    get answeredQuestions( ) {
        return this.getAnsweredQuestions();
    }

    public getAnsweredQuestions() {
        let count = 0;
        for (const element of this.elements) {
            if (element instanceof QuestionModel) {
                // For LernFragen `QuestionModel`s, we assume that they represent 1 question each.
                if (element.isAnswered()) {
                    count += 1;
                }
            }
            else if (element instanceof ContainerModel) {
                // For LernModul `ContainerModel`s, we assume that they may contain sub-questions.
                // Therefore we use recursion here:
                count += element.getAnsweredQuestions();
            }
        }
        return count;
    }

    //returns true if every question is answered
    get isAnswered(): boolean {
        return this.getIsAnswered();
    }

    public getIsAnswered() {
        //what should happen with containers, that dont contain question-elements? is the container answered?
        return this.elements.every( (element) =>( element.answered === true) || typeof element.answered === 'undefined' );
    }

    get ratio(): number {
        //return 0 if there are any illegal values (eg 0 totalQuestions)
        return (this.answeredQuestions / this.totalQuestions ? this.answeredQuestions / this.totalQuestions : 0);
    }
    get percentage():number {
        return Math.ceil(this.ratio*100);
    }

    //for Exam
    get correctAnswers() {
        return this.getCorrectAnswers();
    }

    public getCorrectAnswers() {
        var count = 0;
        for (const element of this.elements) {
            if (element instanceof QuestionModel) {
                // For LernFragen `QuestionModel`s, we assume that they represent 1 question each.
                if (element.isAnswered() && element.isCorrect()) {
                    count += 1;
                }
            }
            else if (element instanceof ContainerModel) {
                // For LernModul `ContainerModel`s, we assume that they may contain sub-questions.
                // Therefore we use recursion here:
                count += element.getCorrectAnswers();
            }
        }
        return count;
    }

    /**
     * Searches the model hierarchy for a descendant element meeting the predicate specified in `isDescendant`.
     * If found, a breadcrumb path leading to the identified element is returned.
     *
     * @param isDescendant A predicate applied to all elements.
     */
    public getPathToDescendant(id: string): ElementModel[] | [] {
        if (this.id === id) {
            return [this];
        }
        else {
            for (const child of this.children) {
                if (child.id === id) {
                    return [this, child];
                }
                if (child instanceof ContainerModel) {
                    const path = child.getPathToDescendant(id);
                    if (path && path.length) {
                        return [this, ...path];
                    }
                }
            }
            return [];
        }
    }
}


export default ContainerModel;