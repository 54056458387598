import Element from '../element/Element';
import AudioVideoModel from './AudioVideoModel';

export default class AudioVideo extends Element {

    readonly model:AudioVideoModel;

    constructor( model:AudioVideoModel ) {
        super( model, 'lm.audio-video-element' );
    }
}
