import ImageModel from './ImageModel';
import Element from '../element/Element';


class Image extends Element {

    readonly model: ImageModel;

    constructor(model: ImageModel) {
        super(model, 'lm.image-element');
    }
}


export default Image;
