import Decorator, { DecoratorFunction } from 'lf/decorators/Decorator';
import QuestionView from 'lf/slides/Question/Question';
import QuestionModel from 'lf/slides/Question/QuestionModel';
import SlideView from 'lf/slides/Slide/Slide';
import SlideModel from 'lf/slides/Slide/SlideModel';

import IoC from 'ln/ioc/IoC';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';


/**
 * Adds a feedback button to every slide
 */
class FeedbackButtonDecorator extends Decorator {

	private feedbackButtonTemplate: string;
	
	public ioc:IoC<DecoratorFunction> = new IoC<DecoratorFunction>();
	
	constructor() {
		super( );
		this.feedbackButtonTemplate = 'lf.feedback-button';
		this.ioc = new IoC<DecoratorFunction>();
		this.setup();
		
	}
	setup() {

		this.ioc.add( 'App\\Cloze',  function ( slideModel: QuestionModel, slideView: QuestionView ) { this.addButton( slideView.node, slideModel )} );
		this.ioc.add( 'App\\SingleChoice',  function ( slideModel: QuestionModel, slideView: QuestionView ) { this.addButton( slideView.node, slideModel )} );
		this.ioc.add( 'App\\MultipleChoice', function ( slideModel: QuestionModel, slideView: QuestionView ) { this.addButton( slideView.node, slideModel )} );
		this.ioc.add( 'App\\DropDown', function ( slideModel: QuestionModel, slideView: QuestionView ) { this.addButton( slideView.node, slideModel )} );
		this.ioc.add( 'App\\DragDrop', function ( slideModel: QuestionModel, slideView: QuestionView ) { this.addButton( slideView.node, slideModel )} );
		this.ioc.add( 'App\\FreeText', function ( slideModel: QuestionModel, slideView: QuestionView ) { this.addButton( slideView.node, slideModel )} );
		this.ioc.add( 'App\\Hotspot', function ( slideModel: QuestionModel, slideView: QuestionView ) { this.addButton( slideView.node, slideModel )} );
		this.ioc.add( 'App\\default', function( slideModel: QuestionModel, slideView: QuestionView ) {  /*do nothing */ } );
	}
	/**
	 * Shows the feedback button and registers a click signal
	 * @param node The node where the button is added as last child
	 */
	public addButton( slideNode: Node, model: QuestionModel ) {
		var buttonNode = Node.fromHTML( Template.render( this.feedbackButtonTemplate ) );
		buttonNode.click.add( model.fireUserAnswered, model );
		slideNode.append( buttonNode );
		buttonNode.click.add( function() {buttonNode.js('feedback-button').addClass('-active')});
	};

	public decorate( slideModel:SlideModel, slideView:SlideView ) {
		var key = slideView.decoratorKey;
		this.ioc.get( slideView.decoratorKey ).call( this, slideModel, slideView );
	}
}

export default FeedbackButtonDecorator;
