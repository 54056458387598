import View from 'ln/view/View';

import LernFragen from 'lf/LernFragen';
import ModelBasedDeco from 'lf/decorators/ModelBasedDecorator';
import RelatedFeedbackDeco from 'lf/decorators/RelatedFeedback';
import SlideModel from 'lf/slides/Slide/SlideModel';

import FeedbackButtonDeco from '../../lernfragen/FeedbackButtonDecorator';


class Quiz extends View {

    public model: SlideModel;
    public lernfragen: LernFragen;

	constructor( model: SlideModel ) {
		super();
        this.defaultTemplate = 'lm.quiz-element';
        this.model = model;
    }
    
    protected  init() {
        
        this.lernfragen = new LernFragen().defaultSetup();

        var slide = this.model;
        
        this.lernfragen.render( this.node.js('quiz') ) as LernFragen;
        this.lernfragen.renderer.ioc.alias( 'App\\FreeText', 'App\\Freetext' );

        var relatedFeedback = new RelatedFeedbackDeco({ posFeedbackProperty: 'posFeedback', negFeedbackProperty: 'negFeedback' });
        this.lernfragen.renderer.userAnswered.add( relatedFeedback.decorate.bind( relatedFeedback ) );

        this.lernfragen.renderer.userAnswered.add( this.setAnswered.bind(this) );

        var feedback = new FeedbackButtonDeco();
        this.lernfragen.renderer.slideChanged.add( feedback.decorate.bind( feedback ) );

        this.lernfragen.renderer.show( slide );
    }

    
    //set the question as answered
    protected setAnswered( item ) {
        item.answered = true;
       this.model.parent.change.dispatch();
    }
    
    protected renderData() {
        return this.model;
	}
}

export default Quiz;

