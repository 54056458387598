import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';

import ExamModuleModel from './ExamModuleModel';
import SubModuleExamView from './SubModuleExam';
import ElementModelRendererIoC from '../element/ElementModelRendererIoC';
import ProgressModule from '../progress-module/ProgressModule';
import { navigationService } from '../../services/NavigationService';


class ExamModule extends ProgressModule {

    readonly model: ExamModuleModel;
    stateTemplate: string;
    

    constructor(model: ExamModuleModel, elementsIoC: ElementModelRendererIoC) {
		super(model, elementsIoC);
        this.model = model;
        this.defaultTemplate = 'lm.module-element';
        this.stateTemplate = 'lm.exam-state';
    }

    init() {
        
        this.updateState();

        if (this.model.state == 'open') {
            this.node.js('module-box').click.add( ( node ) => {
                this.showStep( node );
             });
        }

        this.model.change.add( this.updateState, this);
    }

    public showStep( node ) {
        var details = new SubModuleExamView(this.model, this.elementsIoC).render();
        navigationService.navigateTo(details);
         //update outer navi
         this.model.rootModule.change.dispatch( node );
	 }
   
    public updateState() {
        //exam state
        this.node.js('module-box').parent().addClass('-' + this.model.state );

        var state = Node.fromHTML( Template.render(this.stateTemplate, this.model ) );
        this.node.js('state').html = state.html;

        if (this.model.state != 'open') {
            this.node.js('module-box').click.removeAll();
        }
    }


}

export default ExamModule;