import LinkedVideoModel from './LinkedVideoModel';
import Element from '../element/Element';


class LinkedVideo extends Element {

    readonly model: LinkedVideoModel;

    constructor(model: LinkedVideoModel) {
        super(model, 'lm.linked-video-element');
    }
}


export default LinkedVideo;
