import ContainerModel from '../container/ContainerModel';
import VideoChapterModel from './VideoChapterModel';
import VideoEventModel from './VideoEventModel';


export default class LernVideoModel extends ContainerModel {

    constructor(dto: Object) {
        super(dto);
    }

    public get chapters() {
        return this.get<VideoChapterModel[]>('chapters', []);
    }

    public get events() {
        return this.get<VideoEventModel[]>('elements', []);
    }

    public getTotalQuestions() {
        return this.events.reduce((sum, event) => sum + event.getTotalQuestions(), 0);
    }

    public getAnsweredQuestions() {
        return this.events.reduce((sum, event) => sum + event.getAnsweredQuestions(), 0);
    }

    public getCorrectAnswers() {
        return this.events.reduce((sum, event) => sum + event.getCorrectAnswers(), 0);
    }
}
