import View from 'ln/view/View';

import LernFragen from 'lf/LernFragen';
import SlideModel from 'lf/slides/Slide/SlideModel';


class Tooltip extends View {

    public model: SlideModel;
    public lernfragen: LernFragen;

    constructor(model: SlideModel) {
        super();
        this.defaultTemplate = 'lm.tooltip-element';
        this.model = model;
    }

    protected init() {

        this.lernfragen = new LernFragen().defaultSetup();

        // models are mapped by lernfragenbuch
        var slide = this.model;
        this.lernfragen.render( this.node.js('tooltip') ) as LernFragen;
        this.lernfragen.renderer.show( slide );
    }

    protected renderData() {
        return this.model;
    }
}


export default Tooltip;
