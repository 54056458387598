import ParagraphModel from './ParagraphModel';
import Element from '../element/Element';


class Paragraph extends Element {

    readonly model: ParagraphModel;

    constructor(model: ParagraphModel) {
        super(model, 'lm.paragraph-element');
    }
}


export default Paragraph;
