import QuestionModel from 'lf/slides/Question/QuestionModel';

import ContainerModel from '../container/ContainerModel';
import Quiz from '../quiz/Quiz';

//an event can hav multiple slides, but in reality only has one ... (sc/mc...). still needs to be a containermodel and not an element model
class VideoEventModel extends ContainerModel {

    constructor( json ) {
        super( json );

        // validate the number of questions associated with this event:
        const questionCount = this.questions.length;
        if (questionCount === 0) {
            console.error(`LernVideo event '${this.title}' has no associated question!`);
        }
        else if (questionCount > 1) {
            console.warn(`LernVideo event '${this.title}' has more than one associated question. All questions except the first one will be ignored.`);
        }
    }

    public get questions() {
        return this.get<QuestionModel[]>('elements', []);
    }

    public get time() {
        return this.get<any>('time');
    }

    public get title() {
        return this.get<string>('title');
    }

    public getTotalQuestions() {
        // The way LernModul currently uses LernVideo, an event should never have
        // 0 or more than 1 questions; so this should always return 1.
        return this.questions.length;
    }

    public getAnsweredQuestions() {
        return this.questions.reduce((count, question) => question.isAnswered() ? count + 1 : count, 0);
    }

    public getCorrectAnswers() {
        return this.questions.reduce((count, question) => question.isAnswered() && question.isCorrect() ? count + 1 : count, 0);
    }
}


export default VideoEventModel;