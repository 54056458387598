import View from 'ln/view/View';

import ElementModel from './ElementModel';


abstract class Element extends View {

    constructor(public readonly model: ElementModel, template: string) {
        super(model);
        this.defaultTemplate = template;
    }

    public init() {
        this.node.setAttribute('name', this.model.uid);
        this.node.setAttribute('id', this.model.uid);
    }

    renderData() {
        return this.model;
    }
}


export default Element;
