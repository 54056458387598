import List from 'ln/list/List';
import ListRenderer from 'ln/list/ListRenderer';
import Node from 'ln/node/Node';

import ContainerModel from './ContainerModel';
import Element from '../element/Element';
import ElementModel from '../element/ElementModel';
import ElementModelRendererIoC from '../element/ElementModelRendererIoC';


abstract class Container extends Element {

    model: ContainerModel;

    constructor(model: ContainerModel, template: string, protected readonly elementsIoC: ElementModelRendererIoC) {
        super(model, template);
    }

    protected abstract get elementContainerNode(): Node;

    init() {
        super.init(); //get name and object id in template

        for (const element of this.model.elements) {
            element.parent = this.model;
            element.change.add(event => this.model.change.dispatch(event));
        }

        const renderer = new ListRenderer<ElementModel>(this.elementContainerNode);
        renderer.ioc = this.elementsIoC;
        renderer.selectorFunction = element => element.modelName;
        renderer.source = new List<ElementModel>(this.model.elements);  // this triggers rendering
    }
}


export default Container;
