import ElementModel from '../element/ElementModel';


type FoldableStyle = 'hidden' | 'preview';


export default class FoldableModel extends ElementModel {

    constructor(dto: Object) {
        super(dto);
    }

    get foldableStyle() {
        return this.get<FoldableStyle>('foldableStyle', 'hidden');
    }
}
