import View from 'ln/view/View';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';

import SubModuleView from './SubModule';
import ProgressModuleModel from './ProgressModuleModel'
import ElementModelRendererIoC from '../element/ElementModelRendererIoC';
import { navigationService } from '../../services/NavigationService';


class ProgressModule extends View {

    readonly model: ProgressModuleModel;
    progressChartTemplate: string;
    
    constructor(model: ProgressModuleModel, protected readonly elementsIoC: ElementModelRendererIoC) {
		super(  );
        this.model = model;
        this.defaultTemplate = 'lm.module-element';
        this.progressChartTemplate = 'lm.progress-chart';
    }

    init() {
        this.updateState();
        this.node.js('module-box').click.add( ( node ) => {
           this.showStep( node );
        });
        this.model.change.add( this.updateState, this);
    }
    
    public showStep( node ) {
        var details = new SubModuleView(this.model, this.elementsIoC).render();

        navigationService.navigateTo(details);
         //update outer navi
         this.model.rootModule.change.dispatch( node );
	 }
   
    public updateState() {
        //chart
        var chart = Node.fromHTML( Template.render(this.progressChartTemplate, this.model ) );
        this.node.js('state').html = chart.html;
    }


    protected renderData() {
		return this.model.object();
	}
}

export default ProgressModule;