import Container from '../container/Container';
import CompositeMaterialModel from './CompositeMaterialModel';
import ElementModelRendererIoC from '../element/ElementModelRendererIoC';


class CompositeMaterial extends Container {

    readonly model: CompositeMaterialModel;

    constructor(model: CompositeMaterialModel, elementsIoC: ElementModelRendererIoC) {
        super(model, 'lm.compositematerial-element', elementsIoC);
    }

    protected get elementContainerNode() {
        return this.node.js('elements');
    }
}


export default CompositeMaterial;
