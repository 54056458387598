import Lang from 'ln/lang/Lang';
import Node from 'ln/node/Node';
import Request from 'ln/request/Request';
import setup, { SetupConfig } from 'ln/setup/setup';
import Template from 'ln/template/TemplateManager';
import View from 'ln/view/View';

import ExamModuleModel from './components/exam-module/ExamModuleModel';
import ExamModule from './components/exam-module/SubModuleExam';
import Module from './components/module/Module';
import ModuleModel from './components/module/ModuleModel';
import ProgressModuleModel from './components/progress-module/ProgressModuleModel';
import ProgressModule from './components/progress-module/SubModule';
import SinglePage from './components/single-page/SinglePage';
import SinglePageModel from './components/single-page/SinglePageModel';
import elementsIoC from './setup/elements';
import mapper from './setup/mapper';
import { navigationService } from './services/NavigationService';
import csrfTokenStore from './setup/csrfTokenStore';

//custom templates
// import './templates/icons'; funktioniert nicht, document body existiert noch nicht...
import './templates/lernfragen.html';
import './templates/lernmodule.html';
import './templates/lernvideo.html';
import './templates/slides.html';

import './setup/makeQuestionsResettable';


export async function init( config:SetupConfig ){

	Template.context.setup = setup;

	Template.context.imagePath = function( image:{ file_name:string }, preset:string = 'medium', ext:string = 'png' ){
		return image ? decodeURIComponent( setup.route( 'asset', { file_name:  image.file_name, preset:preset, ext:ext } ).url() )
					 : 'data:image/gif;base64,R0lGODlhAQABAPAAAP8AAP///yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';
		//             ^^^^^^^^^^^^^^^^^^^^^^^
		// A GIF consisting of a single red pixel. We return this data URI as a fallback so that
		// rendering of the remaining page doesn't stop just because an image is missing. We choose
		// a very strong color as a visual cue where an image is missing.
	}

	Template.context.mediaPath = function( file:{ file_name:string, ext:string } ) {
		return file ? decodeURIComponent( setup.route( 'asset', { file_name: file.file_name, preset: 'original', ext: file.ext } ).url() )
		            : 'data:image/gif;base64,R0lGODlhAQABAPAAAP8AAP///yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';
	}

	Template.context.downloadPath = function( file:{ file_name:string } ) {
		return file ? decodeURIComponent( setup.route( 'asset-download', { file_name: file.file_name } ).url() ) : '#';
	}

	Template.context.publicImagePath = function( file_name:string ){
		return decodeURIComponent( setup.route( 'image',  {file_name: file_name}  ).url() );
	}
	// todo	
	// Template.context.icon = function( name:string, classes?:string ) {
	//     return Template.render( 'lm.icon', { name:name, classes:( classes ) ? classes : 'svg-icon' } );
	// }
	
	Template.context.api = setup;
	setup.init( config );
	Lang.add( setup.data( 'lang' ) );
	
	const data = await setup.route( 'moduleData' ).load() as { module: object, singlePages: object[], csrfToken: string };
	var myModule: ModuleModel = mapper.model( data.module );
	var mySinglePage: SinglePageModel[] = mapper.model( data.singlePages );
	var module = new Module(myModule, mySinglePage, elementsIoC);

	csrfTokenStore.csrfToken = data.csrfToken;

	module.render();
	Node.one( '#lernmodul' ).replace( module.node );

	window.addEventListener('hashchange', navigateToHash);
	if (window.location.hash) {
		navigateToHash();
	}

	function navigateToHash() {
		const id = (window.location.hash || '#').substr(1);
		if (!id) return;

		const breadcrumbs = [myModule.getPathToDescendant(id),
		                     ...mySinglePage.map(singlePage => singlePage.getPathToDescendant(id))];
		for (const breadcrumb of breadcrumbs) {
			for (const parent of breadcrumb) {
				let view: View = null;
				if (parent instanceof ProgressModuleModel) {
					view = new ProgressModule(parent, elementsIoC);
				}
				else if (parent instanceof ExamModuleModel && parent.state === 'open') {
					view = new ExamModule(parent, elementsIoC);
				}
				else if (parent instanceof SinglePageModel) {
					view = new SinglePage(parent, elementsIoC);
				}
				if (view) {
					navigationService.navigateTo(view.render(), false);
					const element = document.querySelector('#' + parent.uid);
					if (element) {
						const bounds = element.getBoundingClientRect();
						window.scrollTo(0, window.scrollY + bounds.top - 60);
					}
				}
			}
		}
	}
};
