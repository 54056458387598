import TitleModel from './TitleModel';
import Element from '../element/Element';


class Title extends Element {

    readonly model: TitleModel;

    constructor(model: TitleModel) {
        super(model, 'lm.title-element');
    }
}


export default Title;
