import Node from 'ln/node/Node';
import Signal from 'ln/signal/Signal';
import View from 'ln/view/View';

import NodeStack from 'lnui/nodestack/NodeStack';


export { navigationService };


export type NavigatedListener = () => void;


class NavigationService {

    public readonly navigated: Signal<NavigatedListener>;

    private readonly history: NodeStack;
    private location: View;

    constructor() {
        this.history = new NodeStack();
        this.navigated = new Signal<NavigatedListener>();
        this.location = null;
    }

    set renderTarget(value: Node) {
        this.history.target = value;
    }

    getLocation(): View|null {
        return this.location;
    }

    isAtHome() {
        return this.history.count() < 1;
    }

    navigateTo(target: View, resetHash: boolean = true) {
        this.location = target;
        this.history.stack(target.node);
        this.navigated.dispatch();
        if (resetHash) {
            window.location.hash = '#';
        }
    }

    navigateToHome(resetHash: boolean = true) {
        this.location = null;
        this.history.first();
        this.navigated.dispatch();
        if (resetHash) {
            window.location.hash = '#';
        }
    }
}


const navigationService = new NavigationService();
