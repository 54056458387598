import IoC from 'ln/ioc/IoC';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import View from 'ln/view/View';
import setup from 'ln/setup/setup';

import type SlideModel from 'lf/slides/Slide/SlideModel';
import QuestionModel from 'lf/slides/Question/QuestionModel';

import ExamModuleModel from './ExamModuleModel';
import Container from '../container/Container';
import ElementModel from '../element/ElementModel';
import ElementModelRendererIoC from '../element/ElementModelRendererIoC';
import ExamQuiz from '../quiz/ExamQuiz';
import ContainerModel from '../container/ContainerModel';
import csrfTokenStore from '../../setup/csrfTokenStore';

import { navigationService } from '../../services/NavigationService';

import type DropDownModel from 'lf/slides/DropDown/DropDownModel';
import type HotspotModel from 'lf/slides/HotSpot/HotspotModel';
import type MultipleChoiceModel from 'lf/slides/MultipleChoice/MultipleChoiceModel';
import type SingleChoiceModel from 'lf/slides/SingleChoice/SingleChoiceModel';

class SubModuleExam extends Container {

    readonly model: ExamModuleModel;
    quizElements: string[];
    examButtonTemplate: string;
    examFeedbackTemplate: string;
    confirmationmailTemplate: string;
    feedbackButton: Node;

    static getModifiedElementsIoC(elementsIoC: ElementModelRendererIoC) {

        //clone the elementsIoC, because exam-quiz-models are mapped differently
        //todo: ask mich...
        var keys = elementsIoC.keys();
        var clonedElementsIoC =  new IoC<( model:ElementModel|SlideModel ) => View>();
        keys.forEach( key => {
            clonedElementsIoC.add(key, elementsIoC.get(key));
        });

        // override default quiz ioc
        clonedElementsIoC.add(
            'App\\MultipleChoice',
            (model:MultipleChoiceModel ) => new ExamQuiz(model).render());

        clonedElementsIoC.add(
            'App\\SingleChoice',
            (model:SingleChoiceModel ) => new ExamQuiz(model).render());

        clonedElementsIoC.add(
            'App\\DropDown',
            (model:DropDownModel ) => new ExamQuiz(model).render());

        clonedElementsIoC.add(
            'App\\Hotspot',
            (model:HotspotModel ) => new ExamQuiz(model).render());

        return clonedElementsIoC;
    }

    constructor(model: ExamModuleModel, elementsIoC: ElementModelRendererIoC) {
        super(model, 'lm.sub-module', SubModuleExam.getModifiedElementsIoC(elementsIoC));
        this.examButtonTemplate = 'lf.exam-button';
        this.examFeedbackTemplate = 'lf.exam-feedback';
        this.confirmationmailTemplate = 'lf.confirmationmail-container';
    }

    protected get elementContainerNode() {
        return this.node.js('elements');
    }

    init() {
        super.init();

        this.feedbackButton = Node.fromHTML(Template.render( this.examButtonTemplate ));
        this.feedbackButton.click.add(this.onExamSolved, this);
        this.node.append(this.feedbackButton);
    }

    onExamSolved() {

        const button = this.node.js( 'exam-button' );

        // if the button is a "retry" button:
        if( button.hasClass( '-retry' ) ) {
            // reset questions as far as is possible:
            this.getQuestionsIn( this.model ).filter( canReset ).forEach( question => question.reset() );
            // "navigate" to a freshly rendered version of this exam module page:
            const view = new SubModuleExam( this.model, this.elementsIoC );
            navigationService.navigateTo( view.render(), true );
            return;
        }

        this.node.js('feedback').empty();

        this.model.currentAttempt = this.model.currentAttempt + 1;

        let result: { feedback:string, state:'-correct'|'-wrong', certificate?:unknown };
        if (this.model.correctAnswers >= this.model.successThreshold) {
            this.model.state = 'success';
            result = { feedback:this.model.posFeedback, state:'-correct', certificate:this.model.get('certificate') };
            //disable button
            this.feedbackButton.click.removeAll();
        }
        else {
            result = { feedback:this.model.negFeedback, state:'-wrong' };
            
            if (this.model.currentAttempt >= this.model.attempts) {
                this.model.state = 'failed';
                //disable button
                this.feedbackButton.click.removeAll();
            }

        }
        var panel = Node.fromHTML(Template.render( this.examFeedbackTemplate, result ));

        switch( this.model.state ) {
            case 'open':
                // convert the button into a "retry" button:
                button.html = `Wiederholen (noch ${this.model.attempts - this.model.currentAttempt} Versuche übrig)`;
                button.addClass( '-retry' );
                break;
            case 'success':
            case 'failed':
                button.remove();
                break;
        }

        if ( this.model.get('sendConfirmation') === true && ( this.model.state === 'success' || this.model.state === 'failed' ) ) {

            let mailTemplate = Node.fromHTML(Template.render( this.confirmationmailTemplate, result ))
            panel.append(mailTemplate);

            const email = mailTemplate.one('.email:not(.-repeat)');
            let emailValid = false;
            const validateEmail = () => {
                const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                emailValid = regex.test( String( email.value ).toLowerCase() );
                mailTemplate.one('.confirmationmail-container').toggleClass( '-invalid-syntax', !emailValid );
            };
            email.blur.add( validateEmail );

            const emailRepeat = mailTemplate.one('.email.-repeat');
            let emailRepeatMatches = false;
            const validateEmailRepeat = () => {
                emailRepeatMatches = String( emailRepeat.value ) === String( email.value );
                mailTemplate.one('.confirmationmail-container').toggleClass( '-emails-do-not-match', !emailRepeatMatches );
            };
            emailRepeat.blur.add( validateEmailRepeat );

            mailTemplate.js('send-email').click.add( (node) => {
                validateEmail();
                validateEmailRepeat();
                if ( emailValid && emailRepeatMatches ) {
                    let language = setup.data('localisation').find( (lang)=> {
                        return lang.active === '-active';
                    });

                    const userInputSummary = this.getQuestionsIn( this.model ).map( (question, index) => ({
                        'number': index + 1,
                        'title': 'title' in question ? question[ 'title' ] : '',
                        'type': question.modelName,
                        'result': question.isAnswered() ? ( question.isCorrect() ? 'correct' : 'incorrect' ) : 'unanswered',
                    }) );

                    let json = {
                        email: email.value,
                        language: language.abbreviation,
                        moduleId: this.model.rootModule.id,
                        success: this.model.state === 'success',
                        userInputSummary,
                    }
                    setup.route('post-mail')
                            .method('post')
                            .headers({ 'X-CSRF-TOKEN':csrfTokenStore.csrfToken })
                            .send( JSON.stringify(json))
                            .then( (e)=>{
                                mailTemplate.one('.form').addClass('-hidden');
                                mailTemplate.one('.success-message').removeClass('-hidden');
                                //disable input
                                // mailTemplate.one('.email').setAttribute('disabled', 'disabled');
                                // mailTemplate.js('send-email').click.removeAll();
                    });
                }
            });
        }

        this.node.js('feedback').append(panel);
        

        this.model.change.dispatch(this); //update scorm in main module...
    }

    private getQuestionsIn( container: ContainerModel ): QuestionModel[] {
        const questions = [];
        for( const element of container.elements ) {
            if( element instanceof ContainerModel ) {
                questions.push( ...this.getQuestionsIn( element ) );
            }
            else if( element instanceof QuestionModel ) {
                questions.push( element );
            }
        }
        return questions;
    }
}

function canReset<T>( obj: T ): obj is T & { reset():void } {
    return 'reset' in obj;
}

export default SubModuleExam;
