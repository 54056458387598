import ElementModel from '../element/ElementModel';

interface Asset {
    ext?:string;
    mime?:string;
}

type MediaType = 'audio'|'video';

export default class AudioVideoModel extends ElementModel {

    public readonly file?:Asset;

    constructor( dto:Object ) {
        super({
            caption: null,
            file: null,
            widthStyle: 'read',
            ...dto
        });
    }

    public get type():MediaType {
        return this.file?.mime?.startsWith( 'audio' ) || [ 'mp3', 'wav' ].indexOf( this.file?.ext ) >= 0 ? 'audio' : 'video';
    }
}
