import ContainerModel from '../container/ContainerModel';


export default class ExamModuleModel extends ContainerModel {

    constructor(dto: Object) {
        super(dto);
    }

    get attempts(): number {
        return this.get('attempts', 2);
    }
    //Hack: set by Scorm
    get currentAttempt(): number {
        return this.get('currentAttempt', 0);
    }
    set currentAttempt( attempt: number) {
        this.set( 'currentAttempt', attempt );
    }
    //remove above for non-scorm...

    get successThreshold(): number {
        return this.get('successThreshold', 0);
    }
    get posFeedback(): string {
        return this.get('posFeedback', 'bestanden');
    }
    get negFeedback(): string {
        return this.get('negFeedback', 'nicht bestanden');
    }

    get sendConfirmation(): boolean {
        return this.get('sendConfirmation', false );
    }

    //open, success, failed
    get state() : string {
        return this.get('state', 'open' );
    }
    
    set state( state: string) {
        this.set( 'state', state );
    }
}
