import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';

import AnswerListModel from 'lf/slides/AnswerList/AnswerListModel';
import QuestionModel from 'lf/slides/Question/QuestionModel';

import Video from 'lv/LernVideo';
import ChapterControl from 'lv/controls/ChapterControl';

import LernVideoModel from './LernVideoModel';
import VideoEventModel from './VideoEventModel';
import Element from '../element/Element';
import Quiz from '../quiz/Quiz';
import TimeMonitor from 'lv/TimeMonitor';


class LernVideo extends Element {

    readonly model: LernVideoModel;
    lernvideo: Video;
    quiz: Quiz;
    quizTime: number;
    continueButtonTemplate: string;
    backButtonTemplate: string;

    constructor(model: LernVideoModel) {
        super(model, 'lm.lernvideo-element');
        this.continueButtonTemplate = 'lf.continue-button';
        this.backButtonTemplate = 'lf.back-button';
    }

    init() {
        super.init();

        this.lernvideo = new Video( );
        
        this.lernvideo.scanner.ioc.add( 'video-chapter-control', function( n:Node, lv:Video ){
            return new ChapterControl( lv.player ).render( n );
        });

        this.lernvideo.render( this.node.js( 'lernvideo' ) ) as Video;

        this.lernvideo.monitor.setEvents( this.model.events );
        this.lernvideo.monitor.start.add( this.onVideoEvent, this);
        
        this.lernvideo.player.time.add(currentTime => this.onVideoTimeChanged(currentTime));

        this.lernvideo.ChapterControl.setChapters( this.model.get( 'chapters' ) );
    }

    private get quizContainer() {
        return this.node.js('quiz-container');
    }

    onVideoTimeChanged(currentTime) {
        this.updateQuizVisibility();
        this.lernvideo.monitor.update(currentTime);
    }

    updateQuizVisibility() {
        // If the video is no longer at the playback time of the most recent quiz,
        // then that quiz should not be visible anymore.
        if (this.quiz && !this.isAtTimeApprox(this.quizTime)) {
            this.quizContainer.empty();
        }
    }

    onVideoEvent(event: VideoEventModel) {
        if (!this.isAtTimeApprox(event.time)) return;

        this.lernvideo.player.pause();
        
        this.node.js('video').parent().addClass('slide-overlay -top -show');

        this.quiz = new Quiz(event.questions[0]);
        this.quiz.model.parent = this.model;
        this.quiz.render();
        this.quizTime = this.lernvideo.player.currentTime;

        this.quiz.lernfragen.renderer.userAnswered.add( this.onQuizAnswered, this );

        this.quizContainer.append( this.quiz.node );
    }

    onQuizAnswered(  ) {
        if (!this.quiz.node.js('continue-button')) {
            var continueButton = Node.fromHTML( Template.render( this.continueButtonTemplate ));
            continueButton.click.add(this.onContinue, this)
            this.quiz.node.append( continueButton );
        }

        if ( this.quiz.model instanceof QuestionModel && !this.quiz.model.isCorrect() && !this.quiz.node.js('back-button')) {
            var backButton = Node.fromHTML(Template.render( this.backButtonTemplate ));
            backButton.click.add(this.onBack, this);
            this.quiz.node.append( backButton );
        }
    }

    onBack() {
        // Reset the current quiz to an unanswered state:
        if (this.quiz.model instanceof AnswerListModel) {
            // TODO: There should be a method `QuestionModel.reset()` in LernFragen
            // that resets a question--not just single/multiple choice ones as here!
            // Once LernFragen has been updated to include such method, use it here.
            this.quiz.model.answered = false;
            for (const answer of this.quiz.model.answers) {
                answer.selected = false;
            }
        }

        // Find the time of the previous event (if there is any).
        // Apply a small offset to all times such that the video, when played back,
        // won't trigger an event again right away.
        let previousTime = 0;
        {
            const currentTime = this.lernvideo.player.currentTime - 0.33;
            for (const event of this.model.events) {
                const eventTime = parseFloat(event.time);
                if (eventTime < currentTime) {
                    previousTime = eventTime + 0.33;
                }
            }
        }
        this.lernvideo.player.moveToTime( previousTime );
        this.lernvideo.player.play();
    }

    onContinue( ) {
        this.lernvideo.player.play();
    }

    private isAtTimeApprox(time: number) {
        return Math.abs(time - this.lernvideo.player.currentTime) < 0.33;
    }
}


export default LernVideo;
